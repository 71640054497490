@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: fixed;
	background: $white;
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	padding: 0 15px;
	top: 0;
	left: 0;
	z-index: 20;
	

	/* .header-logo
	----------------------------------------------------------- */
	.header-logo {
		order: 1;
		display: block;
		width: 200px;
		margin-right: auto;
	}


	/* .header-menu
	----------------------------------------------------------- */
	.header-menu,
	.header-menu span {
		@include transition;
	}
	.header-menu {
		order: 3;
		position: relative;
		z-index: 10;
		width: 23px;
		height: 20px;
		margin: 0 0 0 15px;
		cursor: pointer;

		&__item {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: $textColor;
			@include radius(2);

			&:nth-of-type(1) { top: 0; }
			&:nth-of-type(2) { top: 9px; }
			&:nth-of-type(3) { bottom: 0; }
		}
		&.active {
			.header-menu__item {
				background: $white;
				
				&:nth-of-type(1) { transform: translateY(9px) rotate(-315deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-9px) rotate(315deg); } 
			}
		}
	}


	/* .gnav
	----------------------------------------------------------- */
	.gnav {
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 80px 15px 15px;
		background: rgba(#000, 0.85);
		opacity: 0;
		visibility: hidden;
		@include transition;
		overflow-y: scroll;

		&__item {
			display: flex;
			align-items: center;
			padding: 15px 10px;
			border-bottom: 1px solid rgba($white, 0.2);
			color: $white;
			font-size: $m;
			
			&:first-child {
				border-top: 1px solid rgba($white, 0.2);
			}
			.icon-angle-right {
				margin: 0 10px 0 0;
				color: $white;
			}
		}
		
		.show & {
			z-index: 6;
			opacity: 1;
			visibility: visible;
		}
	}

	/* .header-btn
	----------------------------------------------------------- */
	.header-btn {
		margin: 0 0 40px;
		text-align: center;
	}

	/* .header-sns
	----------------------------------------------------------- */
	.header-sns {
		order: 2;
		display: flex;
		justify-content: center;
		margin: 0 0 50px;
		
		&__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 10px 0;
			background: $white;
			color: #000;
			font-size: 3.0rem;
			@include circle(46);
		}
	}
	
}

