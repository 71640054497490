@charset "utf-8";
/* --------------------------------------------------- */
/* blog */
/* --------------------------------------------------- */
.blog {
	.staff-list {
		&__item {
			margin-bottom: 30px;
			position: relative;
			text-align: center;
			font-size: $xs;
			width: calc(100% / 8);
			padding: 0 10px 30px;
			border-bottom: 1px solid $borderColor;
			text-decoration: none;
			color: $textColor;
			&__img {
				border-radius: 50%;
				margin-bottom: 5px;
				transition: .3s all;
				max-width: 100%;
			}
			&:hover {
				color: $baseColor;
				.staff-list__item__img {
					transform: scale(1.1);
				}
			}
		}
	}
}