@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	
	&__inner {
		display: flex;
		justify-content: space-between;
		max-width: 1040px;
		margin: 0 auto 80px;
		padding: 60px 20px 80px;
	}
	&--column2 {
		.main {
			flex: 1 1 auto;
			width: calc(100% - 240px);
		}
		.side {
			flex: 0 0 auto;
			width: 200px;
			margin: 0 0 0 40px;
		}
	}
}

.row {
	padding: 60px 0 120px;
	
	&__inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 0 20px;
		&__narrow {
			padding: 0 70px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0; }
		}
	}
	&--gray {
		background: #eaeeef;
	}
}

.link_scroll {
	margin-top:-100px;
	padding-top:100px;
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

a[href^="tel:"] {
	cursor: default;
	color: $textColor;
	&:hover {
		opacity: 1.0;
	}
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	font-size: 3.0rem;
	font-weight: $font-medium;
	text-align: center;
	text-shadow: 0 1px 2px rgba(#000, 0.15);
	line-height: 1.2;
	
	@media screen and (max-width:1024px) { height: 30vh; }

	.modelhouse & { background-image: url(/common/img/modelhouse/bg_ttl01.png); }
	.company & { background-image: url(/common/img/company/bg_ttl01.png); }
	.case & { background-image: url(/common/img/case/bg_ttl01.png); }
	.site & { background-image: url(/common/img/site/bg_ttl01.png); }
	.contact &,
	.entry &{ background-image: url(/common/img/contact/bg_ttl01.png); }
	.news & { background-image: url(/common/img/news/bg_ttl01.png); }
	.blog & { background-image: url(/common/img/blog/bg_ttl01.png); }
	.guide & { background-image: url(/common/img/guide/bg_ttl01.png); }
	.privacy &,
	.error &
	 { background-image: url(/common/img/common/bg_ttl01.png); }
	.voice &,
    .event & { background-image: url(/common/img/voice/bg_ttl01.png); }
}

.ttl02 {
	position: relative;
	margin: 0 0 40px;
	padding: 0 0 15px;
	font-size: 2.7rem;
	font-weight: $font-bold;
	text-align: center;
	line-height: 1.3;
	color: $black;
}

.ttl03 {
	margin: 0 0 30px;
	padding: 10px 20px;
	background: #e8eef2;
	font-size: $m;
	font-weight: $font-bold;
	line-height: 1.4;
	color: $black;
	border-left: 2px solid $baseColor;
}

.ttl04 {
	padding: 0 0 5px 0;
	margin: 0 0 20px;
	color: $baseColor;
	font-size: $xl;
	font-weight: $font-bold;
	line-height: 1.5;
	border-bottom: 1px solid #b3b3b3;
	position: relative;
	&::after {
		content: "";
		border-bottom: 1px solid $baseColor;
		left: 0;
		bottom: 0;
		margin-bottom: -1px;
		width: 115px;
		position: absolute;
	}
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 41px) / 2 );
	}
	
	&--middle {
		margin-left: -15px;
		margin-right: -15px;
		
		&> * {
			margin-left: 15px;
			margin-right: 15px;
			width: calc((100% - 61px) / 2 );
		}
	}
	
	&--wide {
		margin-left: -20px;
		margin-right: -20px;
		
		&> * {
			margin-left: 20px;
			margin-right: 20px;
			width: calc((100% - 81px) / 2 );
		}
	}
	
 }

.column3 {
	margin-left: -15px;
	margin-right: -15px;

	&> * {
		margin-left: 15px;
		margin-right: 15px;
		width: calc((100% - 91px) / 3);
	}

	&--wide{
		margin-left: -25px;
		margin-right: -25px;

		&> * {
			margin-left: 25px;
			margin-right: 25px;
			width: calc((100% - 151px) / 3);
		}
	}
	&--narrow{
		margin-left: -10px;
		margin-right: -10px;

		&> * {
			margin-left: 10px;
			margin-right: 10px;
			width: calc((100% - 61px) / 3);
		}
	}
}

.column4 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 22%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--blue {
		& > * {
			&::before {
				background: $baseColorDarken;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #eaeeef;
	}
	
	&--pink {
		background: #faf3f3;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--border-blue {
		border: 1px solid $baseColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 20px 25px;
		background: $white;
		border-top: 1px solid #e2dedf;
		border-bottom: 1px solid #e2dedf;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-lightblue {
		background: #d3e1ea;
		border-bottom: 1px solid $white;
	}
	.bg-blue {
		background: $baseColor;
		color: $white;
		border-bottom: 1px solid $white;
	}
	tr:last-child .bg-lightblue,
	tr:last-child .bg-blue {
		border-bottom: 1px solid #e2dedf;
	}
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 180px;
	height: 40px;
	padding: 0 30px;
	border-style: solid;
	border-width: 1px;
	color: $white;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;

	.icon-fix-left,
	.icon-fix-right {
		font-size: $s;
		@include centering-elements(false, true);
	}
	.icon-fix-left {
		left: 15px;
	}
	.icon-fix-right {
		right: 15px;
	}
	&:hover {
		text-decoration: none;
	}
	
	&--blue {
		background: $baseColorDarken;
		border-color: $baseColorDarken;
		
		&:hover {
			background: $white;
			color: $baseColorDarken;
		}
	}
	&--black {
		background: $black;
		border: 1px solid $black;
		&:hover {
			background: $white;
			color: $textColor;
			border: 1px solid $black;
		}
	}
	&--white {
		background: $white;
		color: $textColor;
		border: 1px solid $black;
		&:hover {
			background: $black;
			border: 1px solid $black;
			color: $white;
		}
	}
	&--gray {
		background: #aaa;
	}
	&--lg {
		min-width: 300px;
		height: 60px;
		font-size: $m;
		max-width: none;
	}
	&--md {
		min-width: 210px;
		height: 60px;
		font-size: $m;
		max-width: none;
	}
	&--sm {
		min-width: inherit;
		height: 30px;
		padding: 0 20px;
		font-size: $s;
		.icon-fix-right {
			right: 5px;
		}
	}
	&--event {
		width: 220px;
	}
}



/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 50px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 8px 17px;
			background: $white;
			border: 1px solid $black;
			color: $black;
			text-decoration: none;
			
			&.current,
			&:hover {
				background: $black;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}

}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColorDarken;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* contact-phone */
/* --------------------------------------------------- */
.contact-phone {
	line-height: 1.5;
	text-align: center;
	
	&__number {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 10px;
		font-size: 3.0rem;
		font-weight: $font-bold;
		line-height: 1;
		color: #1a1a1a;
		
		&__icon {
			margin: 0 5px 0 0;
			color: $baseColorDarken;
			font-size: 2.5rem;
			transform: translateY(2px);
		}
	}
	&__text {
		color: #4d4d4d;
		font-size: $s;
	}
}


/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	width: 100%;
	overflow: hidden;
	border-bottom: 1px solid $baseColorLight;
	margin-bottom: 50px;
	
	&__inner {
		display: flex;
		max-width: 1000px;
		margin: 15px auto;
	}

	&__item {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		padding: 15px 2%;
		color: #929292;
		font-size: $m;
		text-decoration: none;
		line-height: 1.6;
		
		&__text {
			flex: 1 1 auto;
			text-align: center;
		}
		&__icon {
			flex: 0 0 auto;
		}
		&::before {
			left: 0;
			content: '';
			height: 20px;
			border-left: 1px solid $baseColorLight;
			@include centering-elements(false, true);
		}
		&:last-child {
			&::after {
				right: 0;
				content: '';
				height: 20px;
				@include centering-elements(false, true);
				border-right: 1px solid $baseColorLight;
			}
		}

		&--current,
		&:hover {
			color: $baseColor;

		}
	}
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	background: $baseColorDarken;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin: 10px 0 -5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
	
	&::-webkit-input-placeholder {
		color: #B5B5B5;
	}
	&::-moz-placeholder {
		color: #B5B5B5;
	}
	&:-ms-input-placeholder {
		color: #B5B5B5;
	}
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColorDarken;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	cursor: pointer;
	
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColorDarken;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 120px;
	height: 25px;
	border: 1px solid transparent;
	color: $white;
	font-size: 1.3rem;
	line-height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	
	&--event {
		background: $baseColorDarken;
	}
	&--consultation {
		background: #2f9ba9;
	}
	&--tour {
		background: #808080;
	}
	&--news {
		background: #3e92dd;
	}
	&--blog {
		background: $white;
		border: 1px solid $black;
		color: $black;
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	
	&__item {
		margin-right: 5px;
		margin-left: 5px;
		width: 100%;
		padding: 15px 0;
		color: $textColor;
		text-decoration: none;
		line-height: 1.6;
		border-bottom: 1px solid $borderColor;
		display: flex;

		&:hover {
			.news-list__item__text {
				text-decoration: underline;
			}
			.news-list__item__link__img  {
				@include opacity;
			}
		}
		&__text {
			margin-bottom: 10px;
			font-weight: $font-medium;
			color: $black;
		}
		&__thumb {
			position: relative;
			flex: 0 0 auto;
			width: 140px;
			height: 97px;
			margin: 0 15px 0 0;
			overflow: hidden;
			
			& > * {
				max-width: 100%;
				min-height: 100%;
				@include centering-elements(true, true);
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__date {
		margin: 0 10px 0 0;
		color: #4d4d4d;
		line-height: 1.5;
	}
	&__ttl {
		margin: 10px 0 20px;
		padding: 0 0 15px;
		border-bottom: 1px solid #e2dedf;
		font-size: $l;
		font-weight: $font-medium;
		line-height: 1.6;
		color: $black;
	}
	&__block {
		img {
			max-width: 100%;
			height: auto;
		}
		
		strong,
		b {
			font-weight: bold;
		}
		iframe {
			max-width: 100%;
		}
		.accordion {
			position: relative;
			border: none;
			border-radius: 0;
			cursor: pointer;
			margin: 0 0 30px;
			padding: 10px 20px;
			border-left: 2px solid #216ebe;
			background: #e8eef2;
			color: #1a1a1a;
			font-size: 1.6rem;
			font-weight: 700;
			line-height: 1.4;
			.__ico {
				right: 10px;
				font-size: $xxl;
				color: $baseColorDarken;
				@include centering-elements(false, true);
			}
			&.active {
				.__ico::before {
					display: block;
					content: "\e908";
				}
			}
		}
		.accordion-hide {
			padding: 0 0 30px;
		}
	}
}

/* --------------------------------------------------- */
/* product-list */
/* --------------------------------------------------- */
.product-list {
	padding: 35px 50px;
	background: #F2F2F2;
	
	&__item {
		text-decoration: none;
		
		&__ttl {
			margin: 0 0 10px;
			padding: 10px 0;
			border-bottom: 1px solid #b3b3b3;
			
			&__logo {
				&--trettio {
					width: 119px !important;
				}
				&--yamazero {
					width: 150px !important;
				}
				&--bdac {
					width: 144px !important;
				}
				&--aikenka {
					width: 163px !important;
				}
			}
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

/* --------------------------------------------------- */
/* contact-box */
/* --------------------------------------------------- */
.contact-box {
	&__number {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 20px 0;
		font-size: 3.0rem;
		font-weight: $font-bold;
		line-height: 1;
		color: #1a1a1a;
		text-decoration: none;

		&__icon {
			margin: 0 5px 0 0;
			color: $baseColorDarken;
			font-size: 2.5rem;
			transform: translateY(2px);
		}
	}
	.btn--lg {
		height: 50px!important;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: auto; }
	}
}


/* --------------------------------------------------- */
/* case */
/* --------------------------------------------------- */
.case-list {
	&__item {
		margin-bottom: 35px;
		text-decoration: none;
		color: $textColor;
		
		&__img {
			height: 200px;
			overflow: hidden;
			margin-bottom: 15px;
			position: relative;
			width: 100%;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 20vh; }
			
			& > img {
				@include centering-elements(true, true);
				width: auto!important;
				max-width: 315px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 300px; }
			}
		}
			
		&:hover {
			opacity: 0.7;
		}
		
		&__type {
			color: #9facb5;
			font-size: 1.5rem;
			position: relative;
			padding-left: 20px;
			&::before {
				content: "";
				border-bottom: 1px solid #9facb5;
				left: 0;
				width: 15px;
				@include centering-elements(false, true);
			}
		}
	}
}
.case_other {
	.case-list__item {
		&:hover {
			opacity: 1.0;
		}
	}
}

.case_detail {
	.type {
		color: #9facb5;
		position: relative;
		padding: 0 20px;
		display: inline;
		&::before {
			content: "";
			border-bottom: 1px solid #9facb5;
			left: 0;
			width: 15px;
			@include centering-elements(false, true);
		}
		&::after {
			content: "";
			border-bottom: 1px solid #9facb5;
			right: 0;
			width: 15px;
			@include centering-elements(false, true);
		}
	}
}

/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__ttl {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 60px;
		background: #d3e1ea;
		border: 1px solid #b3b3b3;
		font-size: $m;
		font-weight: $font-medium;
		text-align: center;
		color: $black;
	}
	&__item {
		display: flex;
		align-items: center;
		border: 1px solid #b3b3b3;
		border-top: none;
		
		&__link {
			padding: 15px;
			color: $textColor;
			text-decoration: none;
			
			&__icon {
				flex: 0 0 auto;
				margin: 0 10px 0 0;
				color: $baseColor;
			}
			&__text {
				flex: 1 1 auto;
			}
		}

		&:hover {
			opacity: 1;
			.snav__item__link__text {
				color: $baseColorDarken;
			}
		}
		&--active {
			.snav__item__link__text {
				color: $baseColorDarken;
			}
		}
	}
}
