@charset "utf-8";
/* --------------------------------------------------- */
/* modelhouse */
/* --------------------------------------------------- */
.modelhouse {
	
	.bg-gray {
		background: linear-gradient(180deg,#fff 0,#fff 95px,#ebf1f5 0,#ebf1f5);
	}

	/* .price
	----------------------------------------------------------- */
	.price {
		&__inner {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 5px;
		}
		&__ttl {
			width: 110px;
			height: 35px;
			background: $baseColorDarken;
			color: $white;
			font-size: $xl;
			margin-right: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__detail {
			color: $baseColorDarken;
			font-weight: bold;
			font-size: $xxl;
			&__num {
				font-size: 3.6rem;
				line-height: 1.0;
			}
		}
		&__text {
			color: #333;
			font-size: $m;
			font-weight: 500;
			text-align: center;
		}
	}

	/* .repayment
	----------------------------------------------------------- */
	.repayment {
		&__pic {
			width: 100%;
			margin: 0 auto 20px;
		}
		&__inner {
			display: flex;
			justify-content: center;
			margin-bottom: 15px;
		}
		&__ttl {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 0 0 auto;
			width: 80px;
			height: 80px;
			background: $white;
			border-radius: 50%;
			font-size: $s;
			font-weight: bold;
			margin-right: 20px;
		}
		&__body {
			&__price {
				color: $baseColorDarken;
				font-size: $xxl;
				font-weight: bold;
				&__num {
					font-size: 3.6rem;
					line-height: 1.0;
				}
				&__note {
					color: $textColor;
					font-weight: normal;
					font-size: 1.5rem;
					display: block;
				}
			}
		}
		&__gift {
			text-align: center;
			font-size: $l;
			font-weight: bold;
			&__em {
				color: #f33961;
			}
			&__icon {
				font-size: $xxl;
				color: #f33961;
			}
		}
	}
	
	/* .information
	----------------------------------------------------------- */
	.information {
		width: 100%;
		margin: 0 auto;
		
		&__table {
			margin-bottom: 60px;
			th, td {
				font-size: 1.5rem;
			}
		}
		&__photo {
			&__item {
				margin-bottom: 30px;
				&__pic {
					margin-bottom: 10px;
				}
			}
		}
		&__detail {
			padding: 20px;
			margin-bottom: 65px;
		}
		&__contact {
			text-align: center;
			&__inner {
			}
			&__btn {
			}
		}
	}
}