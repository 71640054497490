@charset "utf-8";
/* ============================================================ */
/* guide */
/* ============================================================ */
.guide {
	
	/* ============================================================ */
	/* system */
	/* ============================================================ */
	.guide-system {
		&__item {
			margin-bottom: 40px;
			&__img {
				position: relative;
				margin-bottom: 10px;
				&__icon {
					@include centering-elements(true, false);
					margin-top: -18px;
					background: $baseColor;
					top: 0px;
					width: 36px;
					height: 36px;
					border: 2px solid $white;
					border-radius: 50%;
					&__number {
						@include centering-elements(true, true);
						font-size: $l;
						font-weight: bold;
						color: $white;
					}
				}
			}
			&__label {
				background: $baseColor;
				color: $white;
				font-size: 1.6rem;
				padding: 0 8px;
				margin-right: 5px;
			}
		}
	}
	
	/* ============================================================ */
	/* flow */
	/* ============================================================ */
	.guide-flow {
		&__item {
			margin-bottom: 60px;
			&__title {
				font-size: 2.0rem;
				font-weight: bold;
				color: $baseColor;
				border-bottom: 1px solid $borderColor;
				padding-bottom: 10px;
				margin-bottom: 20px;
				&__step {
					font-size: 1.6rem;
					display: block;
				}
			}
		}
		&__date-after {
			text-align: center;
			background: #ebf1f5;
			padding: 5px 0;
			width: 40%;
			margin: 0 auto 60px;
			
			&__text {
				font-weight: bold;
				color: $baseColor;
				font-size: 1.6rem;
				&::after {
					content: "\f103";
					display: block;
					line-height: 1.3;
					font-size: 2.0rem;
					font-family: 'icomoon';
				}
			}
		}
	}
	
	
	/* ============================================================ */
	/* carpenter */
	/* ============================================================ */
	.guide-carpenter {
		overflow-x: hidden;
		&__catchcopy {
			font-size: 2.1rem;
			font-weight: bold;
			position: relative;
			text-align: center;
			color: $black;
			margin-bottom: 40px;
			&__text {
				&::before {
					content: "“";
					position: absolute;
					top: 0;
					margin-left: -15px;
					color: $baseColor;
				}
				&::after {
					content: "”";
					position: absolute;
					top: 0;
					margin-left: 5px;
					color: $baseColor;
				}
			}
		}
	}
	
	.craftsman {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		@media screen and (max-width: 1040px) { width: 75%;margin: 0 auto; }
		
		&__tabs {
			*zoom: 1;
			width: 20%;
			@media screen and (max-width: 1040px) { width: 100%; }
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			
			&__thum {
				filter: grayscale(100%);
				-webkit-filter: grayscale(100%);
				@media screen and (max-width: 1040px) { display: none; }
				&:hover {
					filter: none;
				}
				
				a {
					position: relative;
					overflow: hidden;
					display: block;
					width: 100%;
					height: 0px;
					padding-bottom: 100%;
					&:before {
						transition: 0.3s;
						opacity: 0;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						box-sizing: border-box;
						border: solid 3px $baseColor;
						content: "";
					}
					img {
						transition: opacity 0s;
						&::hover {
							opacity: 1;
						}
					}
				}
			}
			
			&__body {
				transition: 0.3s;
				position: relative;
				width: 720px;
				height: 0;
				overflow: hidden;
				visibility: hidden;
				@media screen and (max-width: 1040px) {
					width: 100%;
					position: inherit;
					visibility: visible;
					overflow: inherit;
					height: auto;
				}
				&.on {
					height: 318px;
					visibility: visible;
				}
				&:after {
					content: '';
					width: 0px;
					height: 0px;
					top: 0px;
					position: absolute;
					border-top: 318px solid transparent;
					border-right: 90px solid #fff;
					right: 0%;
					z-index: -10;
				}
				
				&__bg {
					z-index: -20;
					position: absolute;
					width: 100%;
					@media screen and (max-width: 1040px) {
						width: 100%;
						padding-top: 58%;
						margin-bottom: 30px;
						position: inherit;
					}
					&:before {
						width: 28.4%;
						content: "";
						background: $black;
						height: 318px;
						position: absolute;
						@media screen and (max-width: 1040px) {
							display: none;
						}
					}
					&__text {
						box-sizing: border-box;
						width: 45.3%;
						height: 318px;
						padding: 20px 50px 0 20px;
						position: relative;
						@media screen and (max-width: 1040px) {
							background-color: $black;
							width: 100%;
							padding: 15px 0 20px;
							margin: auto;
							position: relative;
							box-sizing: inherit;
							height: auto;
							&:after {
								content: "";
								position: absolute;
								left: 0;
								bottom: 0;
								width: 100%;
								background-color: #fff;
							}
						}
						&:before {
							content: '';
							width: 0px;
							height: 0px;
							top: 0px;
							position: absolute;
							border-bottom: 318px solid transparent;
							border-left: 90px solid $black;
							right: 10%;
							z-index: -10;
							@media screen and (max-width: 1040px) {
								display: none;
							}
						}
						
						&__name {
							font-size: 2.4rem;
							font-weight: bold;
							line-height: 1.5;
							color: #c5c5c5;
							border-bottom: 1px solid $baseColor;
							display: inline;
							@media screen and (max-width: 1040px) {
								padding-left: 20px;
							}
						}
					}
				}
			}
			.craftsman__tabs__thum a {
				&.on:before, &:hover:before {
					opacity: 1;
				}
			}
		}
	}
	.craftsman__tabs {
		&--man01 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_01.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_01.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_01.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man02 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_02.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_02.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_02.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man03 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_16.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_16.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_16.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man04 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_04.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_04.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_04.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man05 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_05.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_05.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_05.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man06 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_06.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_06.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_06.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man07 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_07.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_07.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_07.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man08 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_17.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_17.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_17.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man09 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_09.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_09.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_09.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man10 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_10.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_10.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_10.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man11 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_11.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_11.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_11.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man12 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_12.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_12.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_12.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man13 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_13.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_13.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_13.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man14 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_14.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_14.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_14.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man15 {
			.craftsman__tabs__thum {
				background: url(/common/img/guide/carpenter/pic_carpenter_15.png) 50% 10% no-repeat;
				background-size: cover;
			}
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_15.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_15.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
	}
}