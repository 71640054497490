@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	&__inner {
		display: flex;
		flex-direction: column;
		margin: 0 15px 40px;
	}
	&--column2 {
		.main {
			margin: 0 0 40px;
		}
	}
}

.row {
	padding: 0 0 40px;
	
	&__inner {
		padding: 0 15px;
	}
	&--gray {
		background: #eaeeef;
	}
}

.link_scroll {
	margin-top:-50px;
	padding-top:50px;
}





/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

.lead01 {
	margin: 0 0 20px;
	color: $baseColorDarken;
	font-size: $l;
	font-weight: $font-bold;
	text-align: center;
	line-height: 1.6;
}

.lead02{
	margin: 0 0 20px;
	font-size: $m;
	font-weight: $font-bold;
	text-align: center;
	line-height: 1.6;
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 150px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: $white;
	font-size: $xxl;
	font-weight: $font-medium;
	text-align: center;
	text-shadow: 0 1px 2px rgba(#000, 0.15);
	line-height: 1.4;
	
	.contact &,
	.entry & { background-image: url(/common/img/contact/bg_ttl01.png); }
	.news & { background-image: url(/common/img/news/bg_ttl01.png); }
	.blog & { background-image: url(/common/img/blog/bg_ttl01.png); }
	.case & { background-image: url(/common/img/case/bg_ttl01.png); }
	.site & { background-image: url(/common/img/site/bg_ttl01.png); }
	.guide & { background-image: url(/common/img/guide/bg_ttl01.png); }
	.modelhouse & { background-image: url(/common/img/modelhouse/bg_ttl01.png); }
	.company & { background-image: url(/common/img/company/bg_ttl01.png); }
	.error &,
	.privacy &
	 { background-image: url(/common/img/common/bg_ttl01.png); }
	.voice &,
    .event & { background-image: url(/common/img/voice/bg_ttl01.png); }
}

.ttl02 {
	position: relative;
	margin: 0 0 30px;
	font-size: 2.4rem;
	font-weight: $font-bold;
	text-align: center;
	line-height: 1.3;
	color: $black;
}

.ttl03 {
	margin: 0 0 15px;
	padding: 10px 20px;
	background: #e8eef2;
	font-size: $m;
	font-weight: $font-bold;
	line-height: 1.4;
	color: $black;
	border-left: 2px solid $baseColor;
}

.ttl04 {
	padding: 0 0 5px 0;
	margin: 0 0 10px;
	color: $baseColor;
	font-size: $xl;
	font-weight: $font-bold;
	line-height: 1.5;
	border-bottom: 1px solid #b3b3b3;
	position: relative;
	&::after {
		content: "";
		border-bottom: 1px solid $baseColor;
		left: 0;
		bottom: 0;
		margin-bottom: -1px;
		width: 115px;
		position: absolute;
	}
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.sp-column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--pink {
		& > * {
			&::before {
				background: $baseColorDarken;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #eaeeef;
	}
	
	&--pink {
		background: #faf3f3;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--border-blue {
		border: 1px solid $baseColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	table-layout: fixed;
	border-top: 1px solid #e2dedf;
	border-bottom: 1px solid #e2dedf;
	font-size: $xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border-top: 1px solid #e2dedf;
		border-bottom: 1px solid #e2dedf;
		text-align: left;
		vertical-align: middle;
	}
	.bg-lightblue {
		background: #d3e1ea;
	}
	.bg-blue {
		background: $baseColor;
		color: $white;
	}
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 240px;
	height: 45px;
	margin: 5px 0;
	padding: 0 30px;
	color: $white;
	font-size: 1.5rem;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	
	.icon-fix-left,
	.icon-fix-right {
		font-size: $m;
		@include centering-elements(false, true);
	}
	.icon-fix-left {
		left: 10px;
	}
	.icon-fix-right {
		right: 10px;
	}
	
	&--blue {
		background: $baseColorDarken;
	}
	&--black {
		background: $black;
	}
	&--white {
		background: $white;
		color: $textColor;
		border: 1px solid $black;
	}
	&--gray {
		background: #aaa;
	}
	&--lg {
		width: 100%;
		height: 55px;
		font-size: $s;
	}
	&--md {
		width: 48%;
		min-width: 100px;
		height: 45px;
		padding: 0 20px;
		font-size: $s;
	}
	&--sm {
		min-width: inherit;
		height: 30px;
		min-width: 0;
		padding: 0 20px;
		font-size: $xxs;
		.icon-fix-right {
			right: 5px;
		}
	}
	&--event {
		min-width: 180px;
		padding: 0 20px;
	}
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: $white;
			border: 1px solid $black;
			color: $black;
			text-decoration: none;
			
			&.current {
				background: $black;
				color: $white;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColorDarken;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	margin: 0 0 40px;
	
	&__item {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 15px;
		background: #ebf1f5;
		border-bottom: 1px solid $baseColorLight;
		color: $textColor;
		text-decoration: none;
		line-height: 1.5;
		
		&__text {
			flex: 1 1 auto;
			font-size: 1.5rem;
		}
		&__icon {
			flex: 0 0 auto;
			margin: 0 0 0 15px;
		}
		&--current {
			color: $baseColor;
		}
	}
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: $baseColorDarken;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin: 10px 0 -5px;
	font-weight: bold;
}

input[type="text"],
input[type="date"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
	
	&::-webkit-input-placeholder {
		color: #B5B5B5;
	}
	&::-moz-placeholder {
		color: #B5B5B5;
	}
	&:-ms-input-placeholder {
		color: #B5B5B5;
	}
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select,
input[type="date"] {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColorDarken;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColorDarken;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 110px;
	height: 22px;
	padding: 0 5px;
	border: 1px solid transparent;
	color: #fff;
	font-size: $xxs;
	text-align: center;
	line-height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	
	&--event {
		background: $baseColorDarken;
	}
	&--consultation {
		background: #2f9ba9;
	}
	&--tour {
		background: #808080;
	}
	&--news {
		background: #3e92dd;
	}
	&--blog {
		background: $white;
		border: 1px solid $black;
		color: $black;
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	
	&__item {
		margin-right: 5px;
		margin-left: 5px;
		width: 100%;
		padding: 15px 0;
		color: $textColor;
		text-decoration: none;
		line-height: 1.6;
		border-bottom: 1px solid $borderColor;
		display: flex;

		&__text {
			margin-bottom: 10px;
			font-weight: $font-medium;
			color: $black;
		}
		&__thumb {
			position: relative;
			flex: 0 0 auto;
			width: 90px;
			height: 60px;
			margin: 0 10px 0 0;
			overflow: hidden;
			
			& > * {
				width:auto;
				@include centering-elements(true, true);
			}
		}
	}
}

/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__date {
		margin: 0 10px 0 0;
		color: #4d4d4d;
		line-height: 1.5;
	}
	&__ttl {
		margin: 10px 0 20px;
		padding: 0 0 15px;
		border-bottom: 1px solid #e2dedf;
		color: $black;
		font-size: $l;
		font-weight: $font-medium;
		line-height: 1.5;
	}
	
	&__block {
		padding: 0 0 30px;
		strong,
		b {
			font-weight: bold;
		}
		
		table {
			width: 100%;
			table-layout: fixed;
			font-size: $xs;
			line-height: 1.6;
			word-break: break-all;
			word-wrap: break-word;
			
			&.table--event {
				table-layout: inherit;
			}

			th,
			td {
				padding: 10px;
				background: #fff;
				border-top: 1px solid #e2dedf;
				border-bottom: 1px solid #e2dedf;
				text-align: left;
				vertical-align: middle;
			}
			th {
				background: #faf3f3;
			}
		}
		
		img {
			max-width: 100%;
			height: auto;
		}
		iframe {
			max-width: 100%;
			height: 300px;
		}
		
		strong,
		b {
			font-weight: bold;
		}
		
		.accordion {
			position: relative;
			border: none;
			border-radius: 0;
			cursor: pointer;
			margin: 0 0 30px;
			padding: 10px 20px;
			border-left: 2px solid #216ebe;
			background: #e8eef2;
			color: #1a1a1a;
			font-size: 1.6rem;
			font-weight: 700;
			line-height: 1.4;
			.__ico {
				right: 10px;
				font-size: $xl;
				color: $baseColorDarken;
				@include centering-elements(false, true);
			}
			&.active {
				.__ico::before {
					display: block;
					content: "\e908";
				}
			}
		}
		.accordion-hide {
			padding: 0 0 30px;
		}
	}
}


/* --------------------------------------------------- */
/* product-list */
/* --------------------------------------------------- */
.product-list {
	padding: 15px 10px;
	background: #F2F2F2;
	
	&__item {
		display: flex;
		margin: 0 0 20px;
		text-decoration: none;
		
		&:last-child {
			margin: 0;
		}
		
		&__ttl {
			margin: 0 0 5px;
			padding: 0 0 7px;
			border-bottom: 1px solid #b3b3b3;
			line-height: 1;
			
			&__logo {
				&--trettio {
					width: 80px !important;
				}
				&--yamazero {
					width: 100px !important;
				}
				&--bdac {
					width: 100px !important;
				}
				&--aikenka {
					width: 100px !important;
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* product-list */
/* --------------------------------------------------- */
.contact-phone-flex {
	.contact-phone {
		position: relative;
		padding: 0 0 30px;
		
		&::after {
			content: "";
			@include centering-elements(true, false);
			bottom: 0;
			width: 80px;
			border-bottom: 1px solid #b2b2b2;
		}
	}
}


/* --------------------------------------------------- */
/* contact-box */
/* --------------------------------------------------- */
.contact-box {
	&__number {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 20px 15px;
		font-size: 3.0rem;
		font-weight: $font-bold;
		line-height: 1;
		color: #1a1a1a;
		text-decoration: none;

		&__icon {
			margin: 0 5px 0 0;
			color: $baseColorDarken;
			font-size: 2.5rem;
			transform: translateY(2px);
		}
	}
	.btn--lg {
		height: 50px!important;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: auto; }
	}
}

/* --------------------------------------------------- */
/* case */
/* --------------------------------------------------- */
.case_list {
	.case-list__item {
		margin-bottom: 25px;
		text-decoration: none;
		color: $textColor;
		
		&__img {
			height: 120px;
			overflow: hidden;
			margin-bottom: 5px;
			position: relative;
			width: 100%;
			
			& > img {
				@include centering-elements(true, true);
				width: auto!important;
				max-width: 300px;
				min-height: 100%;
			}
		}
		
		&__type {
			color: #9facb5;
			font-size: 1.4rem;
			line-height: 1.3;
			position: relative;
			padding-left: 15px;
			display: inline-block;
			&::before {
				content: "";
				border-bottom: 1px solid #9facb5;
				left: 0;
				width: 10px;
				top: 1.0rem;
				position: absolute;
			}
		}
	}
}

.case_detail {
	.type {
		color: #9facb5;
		position: relative;
		padding: 0 20px;
		display: inline;
		&::before {
			content: "";
			border-bottom: 1px solid #9facb5;
			left: 0;
			width: 15px;
			@include centering-elements(false, true);
		}
		&::after {
			content: "";
			border-bottom: 1px solid #9facb5;
			right: 0;
			width: 15px;
			@include centering-elements(false, true);
		}
	}
}

/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__ttl {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 60px;
		background: #d3e1ea;
		border: 1px solid #b3b3b3;
		font-size: $m;
		font-weight: $font-medium;
		text-align: center;
		color: $black;
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		border: 1px solid #b3b3b3;
		border-top: none;
	}
	&__item {
		width: 50%;
		margin: 0 0 -1px;
		border-bottom: 1px solid #b3b3b3;

		&:nth-child(2n+1) {
			border-right: 1px solid #b3b3b3;
		}
		&__link {
			display: flex;
			align-items: center;
			padding: 15px;
			color: $textColor;
			text-decoration: none;
			
			&__icon {
				flex: 0 0 auto;
				margin: 0 10px 0 0;
				color: $baseColorDarken;
			}
			&__text {
				flex: 1 1 auto;
			}
		}
		&--active {
			.snav__item__link__text {
				color: $baseColorDarken;
			}
		}
	}
	
}
