@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
@media screen and (max-width: 375px) {
	html {
		font-size: calc(100vw / 37.5);
	}
}

body {
	background: $white;
	height: 1500px;
	
	&.show {
		height: 100vh;
		overflow: hidden;
	}
}

.wrap {
	color: $textColor;
	font-family: 'Noto Sans Japanese', sans-serif;
	font-size: $s;
	font-weight: $font-regular;
	line-height: 1.8;
	margin-top: 50px;
}

.pc-view {
	display: none !important;
}

a {
	color: $baseColorDarken;
	text-decoration: underline;
	@include transition;
	
	header &,
	footer &,
	.pagetop & {
		text-decoration: none;
	}
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 20px 15px 30px;
	font-size: $xxs;
	line-height: 1.4;
	color: $baseColor;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				color: #c3cfd9;
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
		
		&__link {
			text-decoration: none;
			color: #c3cfd9;
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	max-width: 100%;
	height: 50px;
	background: #1a1a1a;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		color: #c9c9c9;
		flex-wrap: wrap;
		@include transition;

		&__text {
			font-size: 1.3rem;
			text-align: center;
			&::before {
				display: block;
				content: "\f106";
				font-size: 3.3rem;
				font-family: 'icomoon';
				line-height: 0.5;
			}
		}
	}
}
