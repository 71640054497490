@charset "utf-8";

@import url("//fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import "_reset";
@import "_icon";
@import "_mixin";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_company";
	@import "pc/_site";
	@import "pc/_guide";
	@import "pc/_blog";
	@import "pc/_contact";
	@import "pc/_modelhouse";
	@import "pc/_voice";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_company";
	@import "sp/_site";
	@import "sp/_guide";
	@import "sp/_blog";
	@import "sp/_contact";
	@import "sp/_modelhouse";
	@import "sp/_voice";
}
@import "_utility";