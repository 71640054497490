@charset "utf-8";
/* ============================================================ */
/* site */
/* ============================================================ */
.site_detail {
	.site__item {
		margin-bottom: 60px;
		.ttl04 {
			font-size: $m;
			margin-bottom: 5px;
		}
		&__thumb {
			height: 63px;
			overflow: hidden;
			margin-bottom: 10px;
			position: relative;
			
			& > img {
				@include centering-elements(true, true);
				width: auto!important;
				max-width: 100px;
			}
			
			&:hover {
				opacity: 0.7;
			}
		}
	}
}