@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.news-list,
	.news-list > * * {
		max-width: none;
	}	
	
	header {
		z-index: 10;
	}
	
	.index-ttl01 {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 0 20px;
		font-size: 1.3rem;
		font-weight: $font-demilight;
		text-align: center;
		line-height: 1.5;
		color: #666666;

		&::before {
			display: block;
			content: attr(data-text);
			color: $black;
			font-size: 3.0rem;
			font-weight: $font-black;
		}
	}


	.index-ttl02 {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 1.3rem;
		color: #666666;
		line-height: 1.0;
		
		&::before {
			content: attr(data-text);
			color: $black;
			font-size: 2.8rem;
			font-weight: $font-black;
			padding-right: 5px;
			padding-bottom: 10px;
		}
	}
	


	/* .visual
	----------------------------------------------------------- */
	.visual {
		position: relative;
		overflow: hidden;
		
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__ttl {
			width: 80%;
		}
	}
	
	/* news
	----------------------------------------------------------- */
	.news-list,
	.news-list > * * {
		max-width: none;
	}
	.news-list {
		&__item {
			width: calc((100% - 21px) / 2 );
			border-bottom: none;
			display: block;
			
			&__thumb {
				width: 100%;
				margin: 0 0 15px 0;
				height: 130px;
			}
		}
	}


	/* .process
	----------------------------------------------------------- */
	.process {
		&__list {
			margin: 0 0 25px;
			
			&__item {
				position: relative;
				
				&:not(:last-child) {
					margin: 0 0 3px;
				}
				&__body {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					padding: 0 10.5%;
					color: $black;
					font-size: $xxl;
					line-height: 1.5;
					font-weight: $font-medium;
					
					&::before {
						left: 0;
						content: '';
						width: 8%;
						border-top: 1px solid $black;
						@include centering-elements(false, true);
					}
				}
				&__number {
					flex: 0 0 auto;
					margin: 0 9% 0 0;
					font-size: 2.8rem;
				}
				&__text {
					flex: 1 1 auto;
					font-weight: $font-medium;
				}
			}
		}
	}

	/* .quality
	----------------------------------------------------------- */
	.quality {
		&__bg {
			background: url(/common/img/index/bg_index01.png) center center repeat;
			background-size: 85px 85px;	
		}
		.row {
			padding: 40px 0 20px;
			&__inner {
				padding: 0;
			}
		}
		.lineup {
			
			&__item {
			position: relative;
			margin: 0 0 2px;

				&__inner {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				&__ttl {
					position: relative;
					z-index: 1;
					font-size: 2.8rem;
					color: $white;
					text-align: center;
					font-weight: $font-bold;
					&::after {
						font-size: 1.6rem;
						font-weight: $font-demilight;
						display: block;
						content: attr(data-text);
						text-align: center;
					}
				}
			}
		}
	}

	/* .works
	----------------------------------------------------------- */
	.slider {
		.column > * * {
			max-width: none;
		}
		.row__inner {
			padding: 0;
		}
	}
	.works,
	.site {
		&__slider {
			margin-left: 30px;
			margin-right: 30px;
			max-width: none;
			
			&__link {
				overflow: hidden;
				position: relative;
				height: 210px;
				display: block;
				@media screen and (max-width:374px) { height: 170px; }
				
				&__img {
					@include centering-elements(true, true);
					max-width: 315px;
				}
			}
		}
		.slick-list {
			max-width: none;
		}
		.slick-prev,
		.slick-next {
			width: auto;
			height: auto;
			z-index: 3;

			&:before {
				font-family: 'icomoon';
				font-size: 29px;
				color: #b3b3b3;
			}
		}
		.slick-prev {
			left: -20px;
			&:before {
				content: "\e905";
			}
		}
		.slick-next {
			right: -20px;
			&:before {
				content: "\e904";
			}
		}
	}


	/* .voice
	----------------------------------------------------------- */
	.voice {
		background: #eaeeef;
		margin-bottom: 30px;
		&__inner {
			background: url(/common/img/index/pic_voice_01.png) top left no-repeat;
			background-size: contain;
		}
		&__text {
			padding-top: 40%;
		}
	}


	/* .instagram
	----------------------------------------------------------- */
	.instagram {
		display: flex;
		flex-wrap: wrap;

		&__item {
			width: calc(100% / 3);
			background: #fafafa;
			
			&__link {
				position: relative;
				display: block;
				overflow: hidden;
			}
			&__img {
				position: relative;
				padding-bottom: 100%;
				overflow: hidden;
				
				& > * {
					width: auto;
					height: auto;
					max-width: 160%;
					max-height: 160%;
					@include centering-elements(true, true);
				}
			}
		}
	}
	/* .index-bnr
	----------------------------------------------------------- */
	.index-bnr {
		&__item {
			border: 1px solid #c0ccce;
		}
	}
	
	
}