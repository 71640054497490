@charset "utf-8";

$baseColor: #216ebe;
$baseColorLight: #c3cfd9;
$baseColorDarken: #0052b9;
$textColor: #4d4d4d;
$borderColor: #e8e8e8;

$white: #fff;
$black: #1a1a1a;

$trettio: #5092a0;
$bdac: #db3333;
$yamaZero: #abcd04;
$aikenka: #b3b3b3;


@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}


@mixin hiragino-min-bold {
	font-family: 'ヒラギノ明朝 W6 JIS2004', 'Hiragino Mincho W6 JIS2004';
}



/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;


/* font-weight
-------------------------------------------------- */
$font-thin: 100;
$font-light: 200;
$font-demilight: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 900;

