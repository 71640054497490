@charset "utf-8";
/* ============================================================ */
/* company */
/* ============================================================ */
.company {
	.ea21 {
		&__item {
			display: flex;
			border: 1px solid $baseColor;
			&__label {
				background: $baseColor;
				color: $white;
				font-weight: bold;
				display: flex;
				align-items: center;
				width: 90px;
				justify-content: center;
				padding: 10px 15px;
			}
			&__text {
				flex: 1 1 0;
				padding: 10px 15px;
				display: flex;
				align-items: center;
			}
		}
		&__icon {
			width: 35px;
			margin: 10px 0;
		}
		&__fin {
			width: 100%;
			height: 60px;
			background: $baseColor;
			text-align: center;
			line-height: 60px;
			color: $white;
			font-weight: bold;
			font-size: 2.4rem;
		}
	}
}

.company_staff {
	.staff__item {
		margin-bottom: 50px;
		padding-bottom: 40px;
		position: relative;
		&__img {
			width: 60%!important;
			border-radius: 50%;
			margin-bottom: 0;
		}
		&__ttl {
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			line-height: 2.5;
			flex-wrap: wrap;
			&__label {
				line-height: 1.5;
			}
			&__name {
				flex: 1 1 auto;
				&__kanji {
					font-weight: bold;
					font-size: 2.0rem;
					color: $black;
					margin: 0 0 0 20px;
				}
			
				&__kana {
					color: $black;
					font-size: $xxs;
				}
			}
		}
		
		&__table {
			margin-bottom: 25px;
			th,
			td {
				padding: 10px 0;
				border-top: 1px solid #e2dedf;
				border-bottom: none;
				font-size: 1.5rem;
				min-height: 93px;
				vertical-align: top;
			}
			th {
				border-top: 1px solid $baseColorDarken;
				width: 28%;
				font-size: 1.4rem;
				white-space: nowrap;
			}
			td {
				color: $black;
				font-size: 1.4rem;
			}
		}
		&__btn {
			position: absolute;
			bottom: 0;
			@include centering-elements(true, false);
		}
		.btn--sm {
			height: 40px;
			min-width: 180px;
		}
	}
}

.company_recruit {
    .row {
        padding: 0;
    }
    .visual {
        background-image: url("/common/img/company/recruit/bg_recruit_sp.png");
        padding-bottom: 157.33%;
        height: 0;
        background-size: contain;
        background-repeat: no-repeat;
        &__inner {
            max-width: 100%;
            padding: 0 15px;
        }
        &__txt {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        &__ttl {
            width: 100%;
            min-height: 121px;
            margin: 20px 0 0 0;
            background-image: url("/common/img/company/recruit/pic_ttl_01.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            font-size: 2.6rem;
            font-weight: $font-medium;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
        }
        .__catch {
            max-width: 75%;
        }
        .__txt {
            margin: 20px 0 0;
            text-align: center;
        }
    }
    .bunner {
        &__item {
            width: 100%;
            height: 134px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-decoration: none;
            @include transition;
            &._graduates_ {
                background-image: url("/common/img/company/recruit/bnr_new_01.png");
                .__logo {
                    max-width: 150px;
                }
                .bunner__ttl {
                    color: #000;
                    margin: 5px 0 0 0;
                    .__en {
                        color: #4C70B4;
                    }
                }
            }
            &._career_ {
                background-image: url("/common/img/company/recruit/bnr_career_01.png");
                .bunner__ttl {
                    color: $white;
                    .__en {
                        color: #ADD0EB;
                    }
                }
            }
            &:hover {
                opacity: 0.7;
            }
        }
        &__ttl {
            font-size: 2.6rem;
            font-weight: $font-medium;
            line-height: 1.3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .__en {
                font-weight: $font-regular;
                font-size: $m;
            }
        }
    }
}