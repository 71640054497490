@charset "utf-8";
/* ============================================================ */
/* voice */
/* ============================================================ */
.voice_detail {
	.wrap {
		overflow: hidden;
	}
	&Head {
		height: 370px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		position: relative;
		margin: 0 0 70px;
		display: flex;
		align-items: center;
		@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 240px;}
		&__img {
			overflow-y: hidden;
			overflow-x: hidden;
			width: 50%;
			height: 100%;
			position: relative;
			min-width: 700px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 500px;}
			.__img {
				width: 100%;
				@include centering-elements(true, true);
			}
		}
		&__txt {
			padding: 0 0 0 30px;
			max-width: 500px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 0 0 0 20px;}
		}
		&__ttl {
			font-weight: $font-bold;
			font-size: 2.7rem;
			margin: 0 0 5px;
			color: #000;
		}
		&__desc {
			color: #9facb5;
			font-size: 1.5rem;
		}
	}
	&Body {
		&__txt {
			&._right_ {
				padding: 0 0 0 20px;
			}
			&._left_ {
				padding: 0 20px 0 0;	
			}
		}
		&__qa {
			margin: 0 0 25px;
		}
		&__ttl {
			display: flex;
			align-items: center;
			font-weight: $font-bold;
			font-size: $l;
			color: #000;
			margin: 0 0 10px;
			&::before {
				flex: 0 0 43px;
				font-size: $xl;
				font-weight: $font-medium;
				background: $baseColor;
				width: 43px;
				height: 43px;
				border-radius: 50%;
				content: "Q";
				color: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 15px 0 0;
			}
		}
	}
}