@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: $white;
	line-height: 1.5;
	padding-bottom: 60px;

	/* .footer-contact
	----------------------------------------------------------- */
	.footer-contact {
		padding: 25px 15px 40px;
		background: #eaeeef;
		color: $textColor;
		
		&__ttl {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 0 20px;
			padding: 0 0 15px;
			line-height: 1.5;
			flex-direction: column;
			font-size: 1.3rem;
			font-weight: $font-demilight;
			color: #666666;
			&::before {
				display: block;
				content: attr(data-text);
				color: $black;
				font-size: 3.0rem;
				font-weight: $font-black;
			}
			&::after {
				bottom: 0;
				content: '';
				width: 25px;
				border-bottom: 1px solid #ddd4d4;
				@include centering-elements(true, false);
			}
			&__text {
				font-size: $xxl;
				
				&::after {
					display: block;
					content: attr(data-text);
					margin: 5px 0 0;
					color: #999;
					font-size: $xs;
				}
			}
		}
		&__body {
			.btn {
				width: 100%;
				height: 65px;
				margin: 0 0 15px;
				font-size: 1.5rem;
			}
		}
	}


	/* .footer-content
	----------------------------------------------------------- */
	.footer-content {
		padding: 35px 0;
		
		&__address {
			text-align: center;
			margin: 0 0 25px;
			
			&__logo {
				display: block;
				width: 260px;
				height: auto;
				margin: 0 auto 20px;
			}

			a {
				color: $textColor;
			}
		}
		&__sns {
			display: flex;
			align-items: center;
			justify-content: center;
			
			&__btn {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 5px;
				background: #333;
				color: $white;
				font-size: $xxl;
				@include circle(36);
			}
		}
	}

	
	/* .fnav
	----------------------------------------------------------- */
	.fnav {
		background: $white;
		
		&__column__ttl {
			font-size: 1.3rem;
			padding: 15px;
			border-bottom: 1px solid #dadada;
			position: relative;

			.icon-angle-down,
			.icon-angle-right {
				content: " ";
				position: absolute;
				right: 20px;
			}

			&__link {
				color: $textColor;
				&--wd {
					position: relative;
					width: 100%;
					display: inline-block;
				}
			}

		}

		&__column__list {
			background: #f8f8f8;

			&__item {
				border-bottom: 1px solid #dadada;

				&__link {
					color: $textColor;
					font-weight: normal;
					padding: 10px 20px;
					display: block;
				}
			}
		}
	}

	/* .copyright
	----------------------------------------------------------- */
	.copyright {
		padding: 15px 0;
		border-top: 1px solid #d2e0e9;
		font-size: $xxs;
		text-align: center;
	}
}

/* .footerMenu
----------------------------------------------------------- */	
.footer-menu {
	background: #e9f0f4;
	color: $baseColorDarken;
	width: 100%;
	height: 0px;
	position: fixed;
	z-index: 5;
	bottom: 0;
	overflow: none;
	left: 0;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;

	&__navi {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__item {
			height: 60px;
			width: calc(100% / 3 );
			border-right: 1px solid #d2e0e9;
			font-size: $xxs;
			display: flex;
			align-items: center;
			justify-content: center;
			&__link {
				text-decoration: none;
				color: $baseColorDarken;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				i {
					width: 100%;
					text-align: center;
					margin-bottom: 2px;
				}
			}
			&:last-child {
				border-right: none;
			}
		}
	}
}

.footer-menu.show {
	border-top: 3px solid $baseColorDarken;
	height: 60px;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
}
