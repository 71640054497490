@charset "utf-8";
/* ============================================================ */
/* contact */
/* ============================================================ */
.contact {
	.loader-bg {
		z-index: 400;
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $white;
		overflow: hidden;
		.loader {
			width: 100%;
			height: 100%;
			z-index: 401;
			position: fixed;
			justify-content: center;
			align-items: center;
			display: flex;
			&__img {
				width: 32px;
				height: 32px;
			}
		}
	}
}