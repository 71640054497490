@charset "utf-8";
/* --------------------------------------------------- */
/* blog */
/* --------------------------------------------------- */
.blog {
	.staff-list {
		&__item {
			margin-bottom: 10px;
			position: relative;
			text-align: center;
			font-size: $xxxs;
			width: calc(100% / 4);
			padding: 0 10px 10px;
			border-bottom: 1px solid $borderColor;
			text-decoration: none;
			color: $textColor;
			&__img {
				border-radius: 50%;
				margin-bottom: 5px;
				transition: .3s all;
			}
		}
	}
}