@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;

	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	color: $textColor;
	font-family: 'Noto Sans Japanese', sans-serif;
	font-size: 1.5rem;
	font-weight: $font-regular;
	line-height: 1.8;
	letter-spacing: 0.1rem;
	margin-top: 100px;
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColorDarken;
	cursor: pointer;
	text-decoration: underline;
	@include transition;
	
	&:hover {
		text-decoration: none;
	}
	header &,
	footer &,
	.pagetop & {
		text-decoration: none;
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	flex: 0 0 auto;
	position: relative;
	z-index: 5;
	height: 40px;
	margin: -40px 0 0;
	background: rgba($baseColorDarken, 0.2);
	font-size: $xs;
	line-height: 1.4;
	color: $baseColorLight;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: 1050px;
		height: 40px;
		margin: 0 auto;
		padding: 0 25px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
		&:last-child {
			color: $white;
		}
		
		&__link {
			color: $baseColorLight;
			text-decoration: none;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	max-width: 100%;
	height: 60px;
	background: #1a1a1a;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		color: #c9c9c9;
		flex-wrap: wrap;
		@include transition;
		
		&:hover {
			@include opacity;
		}
		&__text {
			font-size: 1.3rem;
			text-align: center;
			&::before {
				display: block;
				content: "\f106";
				font-size: 3.3rem;
				font-family: 'icomoon';
				line-height: 0.5;
			}
		}
	}
}


/* --------------------------------------------------- */
/* fix-menu */
/* --------------------------------------------------- */
.fix-menu {
	position: fixed;
	top: 47%;
	right: 0;
	display: flex;
	flex-direction: column;
	transform: translateY(-50%);
	z-index: 10;
	
	&__btn {
		display: flex;
		flex-direction: column;
		width: 50px;
		margin: 1px 0;
		padding: 30px 0;
		background: rgba(#0b375e, 0.9);
		color: $white;
		font-size: $m;
		text-align: center;
		text-decoration: none;
		line-height: 50px;
		@include transition;
		&--model {
			background: rgba(#11568e, 0.9);
		}
		&--event {
			background: rgba(#2f9ba9, 0.9);
		}
		&--recruit {
			background: rgba(#1a1a1a, 0.9);
		}
		&__icon {
			margin: 0 0 8px;
		}
		.icon-icon-contact {
			font-size: 1.4rem;
		}
		&__text {
			writing-mode: vertical-rl;
			transform: rotate(0.001deg);
		}
		&:hover {
			@include opacity;
		}
	}
}


/* --------------------------------------------------- */
/* reform-btn */
/* --------------------------------------------------- */
.reform-btn {
	position: fixed;
	right: 70px;
	bottom: 30px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 140px;
		height: 140px;
		background: $baseColorDarken;
		text-decoration: none;
		color: $white;
		font-size: $m;
		text-align: center;
		@include radius(70);
		@include transition;
		flex-wrap: wrap;
		
		&:hover {
			@include opacity;
		}
		.icon-icon-reform {
			width: 100%;
			font-size: 2.1rem;
		}
	}
	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		.reform-btn__link {
			width: 100px;
			height: 100px;
			@include radius(50);
			font-size: $s;
			line-height: 1.3;
		}
	}
}