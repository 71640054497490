@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: $white;
	line-height: 1.5;
	
	/* .footer-contact
	----------------------------------------------------------- */
	.footer-contact {
		background: #eaeeef;
		color: $textColor;
		
		&__inner {
			display: flex;
			max-width: 1040px;
			margin: 0 auto;
			padding: 60px 20px 50px;
		}
		&__ttl {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 27.5%;
			border-right: 1px solid #ddd4d4;
			line-height: 1.5;
			font-weight: $font-demilight;
			font-size: 1.3rem;
			color: #666666;

			&::before {
				display: block;
				content: attr(data-text);
				color: $black;
				font-size: 3.5rem;
				font-weight: $font-black;
			}
			&__text {
				font-size: $xxl;
				font-weight: $font-medium;
				text-align: center;
				
				&::after {
					display: block;
					content: attr(data-text);
					margin: 5px 0 0;
					color: #999;
					font-size: $s;
				}
			}
		}
		&__body {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 72.5%;
			padding: 0 0 0 5.5%;
			
			@media screen and (max-width: 1024px) {
				padding: 0 0 0 3%;
			}
			
			.btn {
				flex: 1 1 auto;
				max-width: 330px !important;
				min-width: none;
				height: 50px;
				margin: 0 10px 0 0;
			}
		}
		.contact-phone {
			flex: 1 1 auto;
			max-width: 385px;
		}
	}


	/* .footer-content
	----------------------------------------------------------- */
	.footer-content {
		display: flex;
		align-items: center;
		max-width: 1040px;
		margin: 40px auto;
		padding: 0 20px;
		font-size: $s;
		justify-content: space-between;
		
		&__address {
			display: flex;
			align-items: center;
			margin: 0 30px 0 0;
			
			&__logo {
				width: 330px;
				margin: 0 20px 0 0;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 300px;}
			}
		}
		&__sns {
			display: flex;
			
			&__btn {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 10px 0 0;
				background: #333;
				color: $white;
				font-size: $xxxl;
				@include circle(38);
				&:hover {
					background: $baseColorDarken;
				}
			}
		}
	}
	
	
	/* .fnav
	----------------------------------------------------------- */
	.fnav {
		display: flex;
		max-width: 1040px;
		margin: 0 auto 50px;
		padding: 0 20px;
		
		&__column {
			flex: 1 1 auto;
			margin: 0 20px 0 0;
			
			&__ttl {
				margin: 0 0 20px;
				font-size: $s;
				font-weight: $font-medium;
				
				&__link {
					color: #4d4d4d;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
			&__list {
				margin: -5px 0 0;
				color: #b3b3b3;
				font-size: $xs;
				
				&__item {
					display: table;
					margin: 0 0 10px;
					
					&::before {
						display: table-cell;
						content: '-';
						padding: 0 3px 0 0;
					}
					
					&__link {
						color: #808080;
						
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	
	
	/* .fnav
	----------------------------------------------------------- */
	.copyright {
		padding: 20px 0;
		border-top: 1px solid #d2e0e9;
		font-size: $xxs;
		text-align: center;
	}
}