@charset "utf-8";
/* ============================================================ */
/* voice */
/* ============================================================ */
.voice_detail {
	&Head {
		width: 100%;
		margin: 0 0 30px;
		&__img {
			overflow-y: hidden;
			overflow-x: hidden;
			width: 100%;
			height: 180px;
			position: relative;
			.__img {
				width: 100%;
				@include centering-elements(true, true);
			}
		}
		&__txt {
			padding: 0 15px;
		}
		&__ttl {
			font-weight: $font-bold;
			font-size: 2.3rem;
			margin: 0 0 5px;
			color: #000;
		}
		&__desc {
			color: #9facb5;
			font-size: 1.5rem;
		}
	}
	&Body {
		&__qa {
			margin: 0 0 25px;
			&:last-child {
				margin: 0 0 15px;
			}
		}
		&__ttl {
			display: flex;
			font-weight: $font-bold;
			font-size: $l;
			line-height: 1.5;
			color: #000;
			margin: 0 0 10px;
			&::before {
				flex: 0 0 43px;
				font-size: $xl;
				font-weight: $font-medium;
				background: $baseColor;
				width: 43px;
				height: 43px;
				border-radius: 50%;
				content: "Q";
				color: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 15px 0 0;
			}
		}
	}
}