@charset "utf-8";
/* ============================================================ */
/* guide */
/* ============================================================ */
.guide {
	/* ============================================================ */
	/* system */
	/* ============================================================ */
	.guide-system {
		&__item {
			margin-bottom: 40px;
			&__img {
				position: relative;
				margin-bottom: 10px;
				&__icon {
					@include centering-elements(true, false);
					margin-top: -18px;
					background: $baseColor;
					top: 0px;
					width: 36px;
					height: 36px;
					border: 2px solid $white;
					border-radius: 50%;
					&__number {
						@include centering-elements(true, true);
						font-size: $l;
						font-weight: bold;
						color: $white;
					}
				}
			}
			&__label {
				background: $baseColor;
				color: $white;
				font-size: 1.6rem;
				padding: 0 8px;
				margin-right: 5px;
			}
		}
	}
	
	/* ============================================================ */
	/* flow */
	/* ============================================================ */
	.guide-flow {
		&__item {
			margin-bottom: 40px;
			&__title {
				font-size: 2.0rem;
				font-weight: bold;
				color: $baseColor;
				border-bottom: 1px solid $borderColor;
				padding-bottom: 5px;
				margin-bottom: 15px;
				&__step {
					font-size: 1.6rem;
					display: block;
				}
			}
		}
		&__date-after {
			text-align: center;
			background: #ebf1f5;
			padding: 5px 0;
			width: 80%;
			margin: 0 auto 40px;
			
			&__text {
				font-weight: bold;
				color: $baseColor;
				font-size: 1.6rem;
				&::after {
					content: "\f103";
					display: block;
					line-height: 1.3;
					font-size: 2.0rem;
					font-family: 'icomoon';
				}
			}
		}
	}
	
	/* ============================================================ */
	/* carpenter */
	/* ============================================================ */
	.guide-carpenter {
		&__catchcopy {
			font-size: 1.7rem;
			font-weight: bold;
			position: relative;
			text-align: center;
			color: $black;
			margin-bottom: 40px;
			&__text {
				&::before {
					content: "“";
					position: absolute;
					top: 0;
					margin-left: -15px;
					color: $baseColor;
				}
				&::after {
					content: "”";
					position: absolute;
					top: 0;
					margin-left: 5px;
					color: $baseColor;
				}
			}
		}
	}
	
	.craftsman {
		
		&__tabs {
			width: 100%;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			
			&__thum {
				display: none;
			}
			
			&__body {
				width: 100%;
				
				&__bg {
					width: 100%;
					padding-top: 58%;
					margin-bottom: 30px;
					&__text {
						width: 100%;
						height: auto;
						padding: 15px 0 20px;
						background-color: $black;

						&__name {
							font-size: 2.0rem;
							font-weight: bold;
							line-height: 1.5;
							color: #c5c5c5;
							border-bottom: 1px solid $baseColor;
							display: inline;
							padding-left: 15px;
						}
					}
				}
			}
		}
	}
	.craftsman__tabs {
		&--man01 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_01.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_01.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man02 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_02.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_02.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man03 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_16.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_16.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man04 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_04.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_04.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man05 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_05.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_05.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man06 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_06.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_06.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man07 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_07.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_07.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man08 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_17.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_17.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man09 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_09.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_09.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man10 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_10.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_10.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man11 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_11.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_11.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man12 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_12.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_12.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man13 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_13.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_13.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man14 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_14.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_14.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
		&--man15 {
			.craftsman__tabs__body__bg {
				background: url(/common/img/guide/carpenter/pic_carpenter_15.png) 100% 0% no-repeat;
				background-size: contain;
				@media screen and (max-width: 1040px) {
					background: url(/common/img/guide/carpenter/pic_carpenter_15.png) center top no-repeat;
					background-size: 100%;
				}
			}
		}
	}	
}