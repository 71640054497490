@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	position: fixed;
	z-index: 10;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100px;
	padding: 0 10px;
	top: 0;
	left: 0;
	background: $white;
	@include transition;


	/* .header-logo
	----------------------------------------------------------- */
	.header-logo {
		display: block;
		width: 290px;
		margin-right: auto;
		@include transition;
        @media screen and (min-width: 1024.001px) and (max-width: 1200px) {
        	width: 220px;
		}
		@media screen and (max-width: 1024px) {
			width: 180px;
		}
	}


	/* .gnav
	----------------------------------------------------------- */
	.gnav {
		display: flex;
		
		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100px;
			margin: 0 10px;
			color: $textColor;
			font-size: $m;
			
			@media screen and (min-width: 1024.001px) and (max-width: 1200px) {
				margin: 0 8px;
				font-size: 1.5rem;
			}
			@media screen and (max-width: 1024px) {
				margin: 0 4px;
				font-size: $s;
			}
			&::before {
				position: absolute;
				left: 50%;
				bottom: 0px;
				z-index: 5;
				content: "";
				width: 0;
				height: 3px;
				background: $baseColorDarken;
				backface-visibility: hidden;
				transform: translateX(-50%);
				@include transition;
			}
			
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
	}

	/* .header-btn
	----------------------------------------------------------- */
	.header-btn {
		display: none;
	}

	/* .header-sns
	----------------------------------------------------------- */
	.header-sns {
		display: flex;
		align-items: center;
		
		&__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 0 10px;
			background: #1a1a1a;
			color: $white;
			font-size: $xxl;
			@include circle(36);
			
			&:hover {
				background: $baseColorDarken;
			}
			@media screen and (min-width: 1024.001px) and (max-width: 1100px) {
				margin: 0 0 0 5px;
			}
			@media screen and (max-width: 1024px) {
				@include circle(30);
			}
		}
	}
	
	
}


