@charset "utf-8";
/* ============================================================ */
/* company */
/* ============================================================ */
.company {
	.ea21 {
		&__item {
			display: flex;
			border: 1px solid $baseColor;
			&__label {
				background: $baseColor;
				color: $white;
				font-weight: bold;
				display: flex;
				align-items: center;
				width: 90px;
				height: 75px;
				justify-content: center;
				padding: 10px 20px;
			}
			&__text {
				padding: 10px 20px;
				display: flex;
				align-items: center;
			}
		}
		&__icon {
			width: 35px;
			margin: 10px 0;
		}
		&__fin {
			width: 100%;
			height: 60px;
			background: $baseColor;
			text-align: center;
			line-height: 60px;
			color: $white;
			font-weight: bold;
			font-size: 2.4rem;
		}
	}
}

.company_staff {
	.row__inner {
		@media screen and (min-width:  767.5px) and (max-width:1024px) {
			overflow-x: hidden;
		}
	}
	.staff__item {
		margin-bottom: 50px;
		padding-bottom: 40px;
		position: relative;
		&__img {
			border-radius: 50%;
			margin-bottom: 20px;
		}
		&__ttl {
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			line-height: 2.5;
			flex-wrap: wrap;
			@media screen and (min-width:  767.5px) and (max-width:1024px) {
				font-size: 1.3rem;
			}
			&__label {
				line-height: 1.5;
				margin: 0 10px 0 0;
			}
			&__name {
				flex: 1 1 auto;
				&__kanji {
					font-weight: bold;
					font-size: 2.0rem;
					color: $black;
					margin: 0 5px 0 0;
					@media screen and (min-width:  767.5px) and (max-width:1024px) {
						font-size: 1.6rem;
						margin: 0 2px;
					}
				}
				&__kana {
					color: $black;
					font-size: $xxs;
					@media screen and (min-width:  767.5px) and (max-width:1024px) {
						font-size: $xxxs;
					}
				}
			}
		}
		
		&__table {
			margin-bottom: 15px;
			th,
			td {
				padding: 10px 0;
				border-top: 1px solid #e2dedf;
				border-bottom: none;
				font-size: 1.5rem;
				min-height: 93px;
				vertical-align: top;
			}
			th {
				border-top: 1px solid $baseColorDarken;
				width: 32%;
				font-size: 1.4rem;
				white-space: nowrap;
			}
			td {
				color: $black;
			}
		}
		&__btn {
			position: absolute;
			bottom: 0;
			@include centering-elements(true, false);
		}
		.btn--sm {
			height: 40px;
			min-width: 180px;
		}
	}
}

.company_recruit {
    .row {
        padding: 0;
    }
    .visual {
        background-image: url("/common/img/company/recruit/bg_recruit_pc.png");
        height: 700px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: left;
        &__inner {
            max-width: 1000px;
            margin: 0 auto;
            height: 100%;
        }
        &__txt {
            width: 50%;
            height: 100%;
            margin: 0 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        &__ttl {
            width: 100%;
            min-height: 174px;
            background-image: url("/common/img/company/recruit/pic_ttl_01.svg");
            background-repeat: no-repeat;
            background-position: center center;
            font-size: 3.5rem;
            font-weight: $font-medium;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
        }
        .__catch {
            margin: 35px 0 0;
            max-width: 100%;
        }
        .__txt {
            margin: 35px 0 0;
            text-align: center;
        }
    }
    .bunner {
        display: flex;
        align-items: center;
        &__item {
            width: 50%;
            height: 250px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-decoration: none;
            @include transition;
            &._graduates_ {
                background-image: url("/common/img/company/recruit/bnr_new_01.png");
                .__logo {
                    max-width: 226px;
                }
                .bunner__ttl {
                    color: #000;
                    margin: 15px 0 0 0;
                    .__en {
                        color: #4C70B4;
                    }
                }
            }
            &._career_ {
                background-image: url("/common/img/company/recruit/bnr_career_01.png");
                .bunner__ttl {
                    color: $white;
                    .__en {
                        color: #ADD0EB;
                    }
                }
            }
            &:hover {
                opacity: 0.7;
            }
        }
        &__ttl {
            font-size: 3.6rem;
            font-weight: $font-medium;
            line-height: 1.3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .__en {
                font-weight: $font-regular;
                font-size: $m;
            }
        }
    }
}