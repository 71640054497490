@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	.index-ttl01 {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 0 30px;
		text-align: center;
		line-height: 1.5;
		font-weight: $font-demilight;
		font-size: 1.3rem;
		color: #666666;

		&::before {
			display: block;
			content: attr(data-text);
			color: $black;
			font-size: 3.5rem;
			font-weight: $font-black;
		}
	}
	

	.index-ttl02 {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 1.3rem;
		color: #666666;
		line-height: 1.0;

		&::before {
			content: attr(data-text);
			color: $black;
			font-size: 2.8rem;
			font-weight: $font-black;
			padding-right: 5px;
			padding-bottom: 10px;
		}
	}


	/* .visual
	----------------------------------------------------------- */
	.visual {
		position: relative;
		overflow: hidden;
		
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__ttl {
			width: 40%;
		}
	}


	/* .process
	----------------------------------------------------------- */
	.process {
		&__list {
			display: flex;
			margin: 0 -3px 50px ;
			
			&__item {
				position: relative;
				margin: 0 3px;
				
				&__body {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 25% 0 0;
					color: $black;
					font-size: $xl;
					text-align: center;
					line-height: 1.5;
					font-weight: $font-medium;
					
					&::before {
						top: 0;
						content: '';
						height: 20%;
						border-left: 1px solid $black;
						@include centering-elements(true, false);
					}
				}
				&__number {
					flex: 0 0 auto;
					margin: 0 0 15px;
					font-size: $m;
					line-height: 1;
				}
				&__text {
					flex: 1 0 auto;
					line-height: 1.3;
				}
			}
			&__link {
				display: block;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
	
	/* news
	----------------------------------------------------------- */
	.news-list,
	.news-list > * * {
		max-width: none;
	}
	.news-list {
		&__item {
			width: calc((100% - 21px) / 2 );
			border-bottom: none;
			display: block;
			
			&__thumb {
				width: 100%;
				height: 162px;
				margin: 0 0 15px 0;
			}
		}
	}
	
	
	/* .quality
	----------------------------------------------------------- */
	.quality {
		&__bg {
			background: url(/common/img/index/bg_index01.png) center center repeat;
			background-size: 85px 85px;
			padding-bottom: 320px;			
		}
		.lineup {
			position:relative;
			margin-top: -320px;
			
			&__item {
			position: relative;

				&__inner {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				&__ttl {
					position: relative;
					z-index: 1;
					font-size: 2.8rem;
					color: $white;
					text-align: center;
					font-weight: $font-bold;
					&::after {
						font-size: 1.6rem;
						font-weight: $font-demilight;
						display: block;
						content: attr(data-text);
						text-align: center;
					}
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	/* .works
	----------------------------------------------------------- */
	.works,
	.site {
		&__slider {
			overflow: hidden;
			
			.slick-track {
				display: flex;
				justify-content: space-between;
				margin-right: -2px;
				margin-left: -2px;
				& > * {
					margin-right: 2px;
					margin-left: 2px;
				}
			}
			
			&__link {
				position: relative;
				display: block;
				overflow: hidden;
				height: 324px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 250px; }
				
				&__img {
					@include centering-elements(true, true);
					width: auto;
					height: 100%;
					max-width: 490px;
					@media screen and (min-width:  767.5px) and (max-width:1024px) { max-width: 300px; }
				}

				&__body {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					color: $white;
					transform: translateY(100%);
					@include transition;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					text-align: center;

					&__ttl {
						font-size: $l;
						font-weight: $font-medium;
						width: 100%;
						padding: 0 15px;
						margin-bottom: 20px;
					}
					&__address {
						width: 100%;
						font-size: $m;
					}
				}

				&:hover {
					.works__slider__link__body,
					.site__slider__link__body {
						transform: translateY(0);
					}
				}
			}
		}
		.works {
			&__slider__link__body {
				background: rgba($baseColorDarken, 0.85);
			}
		}
		.site {
			&__slider__link__body {
				background: rgba(47,155,169,0.85);
			}
		}
		.btn--md {
			@media screen and (min-width:  767.5px) and (max-width:1024px) { min-width: 150px; }
		}
	}

	/* .voice
	----------------------------------------------------------- */
	.voice {
		background: linear-gradient(to bottom,  #ffffff 0%,#ffffff 40%,#eaeeef 40.1%,#eaeeef 100%);
		&__inner {
			background: url(/common/img/index/pic_voice_01.png) center left no-repeat;
			background-size: 70%;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { background-size: 80%; }
		}
		&__body {
			justify-content: flex-end;
		}
		&__text {
			margin-top: 60px;
			margin-bottom: 60px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { margin-top: 40px;margin-bottom: 40px; }
		}
	}

	/* .instagram
	----------------------------------------------------------- */
	.instagram {
		display: flex;
		flex-wrap: wrap;

		&__item {
			width: calc((100% - 1px) / 6 );
			background: #fafafa;
			
			&__link {
				position: relative;
				display: block;
				overflow: hidden;
				
				&:hover {
					.instagram__item__content {
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}
			}
			&__img {
				position: relative;
				padding-bottom: 100%;
				overflow: hidden;
				
				& > * {
					min-width: 100%;
					min-height: 100%;
					@include centering-elements(true, true);
				}
			}
			&__content {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($baseColorDarken, 0.9);
				color: $white;
				padding: 20px;
				overflow: hidden;
				opacity: 0;
				visibility: hidden;
				transform: translateY(100%);
				word-break: break-word;
				@include transition;
				
				&__date {
					margin: 0 0 10px;
					font-size: $m;
					font-weight: $font-medium;
				}
			}
		}
	}
	/* .index-bnr
	----------------------------------------------------------- */
	.index-bnr {
		margin-left: -0.5%;
		margin-right: -0.5%;
		&__item {
			border: 1px solid #c0ccce;
			margin-left: 0.5%;
			margin-right: 0.5%;
			width: 19%;
			&:hover {
				opacity: 0.7;
			}
		}
	}
}